import { FieldsNameVoc } from 'constants/auth/profile-data'

const registrationFieldsDefault = {
  [FieldsNameVoc.email]: '',
  [FieldsNameVoc.phone]: '',
  [FieldsNameVoc.password]: '',
}

const registrationFieldsStud = {
  [FieldsNameVoc.fullName]: '',
  ...registrationFieldsDefault,
}

enum stepName {
  registration = 'Регистрация',
  information = 'Личная информация',
}

const steps = [stepName.registration, stepName.information]
const stepColors = ['#F17E7E', '#FBDF54', '#6AD975']

const customSelectStyle = {
  container: (provided: any) => ({
    ...provided,
    position: 'absolute',
    top: 'auto',
    marginTop: '10px',
    width: '100%',
    backgroundColor: '#FFFFFF',
    padding: '16px',
    border: '1px solid #EBEBEB',
    borderRadius: '10px',
    zIndex: '2',
  }),
}

export {
  stepName,
  registrationFieldsDefault,
  registrationFieldsStud,
  steps,
  stepColors,
  customSelectStyle,
}
