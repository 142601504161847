import { FC } from 'react'

import RegistrationPopup from './double-registration/double-registration'

import A from 'utils/metrics/analytic'
import { A_REGISTRATION_SUCCESS } from 'constants/analytic-data'
import { SessionPopupsKeys } from 'constants/storage'
interface IProp {
  handleClose: () => void
}

const RegGeriatricsSchoolPopup: FC<IProp> = ({ handleClose }) => {
  const subToSchool = () => {
    sessionStorage.setItem(SessionPopupsKeys.GERIATRICS_SCHOOL_GER_POPUP, 'true')
    A.setEvent(A_REGISTRATION_SUCCESS, { subSource: 'online_school_geriatrics_24' })
  }

  return <RegistrationPopup handleClose={handleClose} onEndStep2={subToSchool} />
}

export default RegGeriatricsSchoolPopup
