import { FC, useState, useRef, MouseEvent } from 'react'
import { Controller, Control, UseFormSetValue, UseFormClearErrors } from 'react-hook-form'
import Image from 'next/image'
import ReactCalendar from 'react-calendar'

import useOnClickOutside from 'hooks/useClickOutside'

import { fieldData } from 'constants/auth/profile-data'
import { STATIC_URL } from 'constants/data'
import { formatDate } from 'utils/formatDate'
import InputValidation from 'components/form-parts/input-validation-react/input-validation-react'

import styles from './controller-calendar-select.module.scss'

interface IProps {
  control: Control<any, any>
  setValue: UseFormSetValue<any>
  name: string
  clearErrors: UseFormClearErrors<any>
  nameInput?: string
  errors?: any
  placeholder?: string
  rules?: {
    required: string
    validate?: (val: string) => string | undefined
  }
  props?: any
}

type CalendarValue = Date | null

type CalendarType = CalendarValue | [CalendarValue, CalendarValue]

const InputPCStyles = {
  inputNameModificator: styles.nameRowStyle,
  innerContainerModificator: styles.inputWrap,
  modificator: styles.inputMod,
}

const ControllerCalendarSelect: FC<IProps> = ({
  control,
  name,
  setValue,
  clearErrors,
  rules,
  errors,
  ...props
}: IProps) => {
  const [valueCalendar, onChangeCalendar] = useState<CalendarType>(null)
  const [isOpen, setOpen] = useState(false)
  const wrapperRef = useRef<HTMLDivElement>(null)

  const handleChangeCalendar = (value: CalendarType, e: MouseEvent<HTMLElement>) => {
    if (!value) return
    e.stopPropagation()
    onChangeCalendar(value)
    setValue(name, value)
    setOpen(false)
    clearErrors(name)
  }

  const closeCalendar = () => {
    setOpen(false)
  }

  useOnClickOutside(true, wrapperRef, closeCalendar)
  return (
    <div className={styles.inputWrapper}>
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field: { ref, ...rest } }) => {
          const normilizeDate = valueCalendar ? formatDate(String(valueCalendar)) : ''
          return (
            <div ref={wrapperRef} onClick={() => setOpen(true)}>
              <div className={styles.inputInner}>
                <InputValidation
                  setInputRef={ref}
                  onClick={() => clearErrors?.(name)}
                  error={errors[name]}
                  {...fieldData[name]}
                  {...rest}
                  {...props}
                  {...InputPCStyles}
                  value={normilizeDate}
                >
                  <Image
                    src={`${STATIC_URL}/icons/icon_calendar.svg`}
                    alt="Календарь"
                    width="14"
                    height="14"
                    loading="lazy"
                    className={styles.calendar}
                  />
                </InputValidation>
              </div>
              {isOpen && (
                <div className={styles.calendarContainer}>
                  <ReactCalendar
                    maxDetail={'month'}
                    minDetail={'year'}
                    onChange={handleChangeCalendar}
                    minDate={new Date()}
                    value={valueCalendar}
                  />
                </div>
              )}
            </div>
          )
        }}
      />
    </div>
  )
}

export default ControllerCalendarSelect
