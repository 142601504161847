import { FC } from 'react'
import clsx from 'clsx'

import { PROD_STATIC_PATH } from 'constants/data'

import styles from './registration-step1.module.scss'

interface IConsent {
  consentModificator?: string
  linkModificator?: string
}

const Consent: FC<IConsent> = ({ consentModificator, linkModificator }) => {
  return (
    <p
      className={clsx(styles.consentRegistred, {
        [consentModificator as string]: consentModificator,
      })}
    >
      Нажимая на кнопку, я принимаю&nbsp;
      <a
        href={`${PROD_STATIC_PATH}/terms.pdf`}
        target="_blank"
        rel="noreferrer"
        className={clsx(styles.consent, { [linkModificator as string]: linkModificator })}
      >
        Пользовательское соглашение
      </a>
      &nbsp;и соглашаюсь на обработку{' '}
      <a
        href={`${PROD_STATIC_PATH}/agreement.pdf`}
        target="_blank"
        rel="noreferrer"
        className={clsx(styles.consent, { [linkModificator as string]: linkModificator })}
      >
        персональных данных&nbsp;
      </a>
    </p>
  )
}

export default Consent
