import { FC } from 'react'
import clsx from 'clsx'

import styles from './top-steps.module.scss'

interface ITopSteps {
  currentStep: number
}

const TopSteps: FC<ITopSteps> = ({ currentStep }) => {
  return (
    <div className={styles.wrapper}>
      <div className={clsx(styles.step, { [styles.noActive]: currentStep !== 1 })}>
        <span className={styles.stepNum}>1</span>Регистрация
      </div>
      <div className={clsx(styles.step, { [styles.noActive]: currentStep !== 2 })}>
        <span className={styles.stepNum}>2</span>Личные данные
      </div>
    </div>
  )
}

export default TopSteps
