import { FC, useState } from 'react'
import { useRouter } from 'next/router'

import RegistrationStep1 from 'components/fullscreen-popups/registration/double-registration/parts/registration-step1/registration-step1'
import RegistrationStep2 from './parts/registration-step2/registration-step2'
import Button from 'components/parts/button/button'
import ButtonThemes from 'components/parts/button/themes'
import TopSteps from './parts/top-steps/top-steps'
import PopupWrapperNew from 'containers/popup-wrapper-new/popup-wrapper-new'
import useBannerTelegramBottom from 'store/popups/useBannerTelegramBottom'
import Auth from 'store/auth'

import styles from './double-registration.module.scss'

interface IRegistrationPopup {
  handleClose: () => void
  onSubmitStep1?: () => void
  onEndStep2?: () => void
}

const DoubleRegistartion: FC<IRegistrationPopup> = ({ handleClose, onSubmitStep1, onEndStep2 }) => {
  const router = useRouter()
  const { query, asPath } = router

  const [step, setSteps] = useState(1)
  const [confirm, setConfirm] = useState(false)

  const { isSubscribedToTelegram } = Auth.useAuth()
  const { openBannerTelegramBottom } = useBannerTelegramBottom()

  const isWebinaridPage = query.id && asPath.includes('/webinar/')

  const onSubmitEnd = () => {
    setSteps(2)
    if (!isSubscribedToTelegram && isWebinaridPage) openBannerTelegramBottom()
  }

  const confirmExitHandler = () => {
    if (step === 2 && isWebinaridPage) {
      setConfirm(true)

      return
    }
    handleClose()
  }

  const continueHandler = () => setConfirm(false)

  return (
    <>
      <PopupWrapperNew
        popupBlockMod={styles.popupHelper}
        bgNoClicked
        closePopup={confirmExitHandler}
      >
        <div className={styles.wrapper}>
          <TopSteps currentStep={step} />

          {step === 1 && <RegistrationStep1 onSubmitEnd={onSubmitEnd} />}
          {step === 2 && (
            <RegistrationStep2
              title={
                isWebinaridPage
                  ? 'Заполните все обязательные поля для регистрации на мероприятие.'
                  : 'Заполните поля для полного доступа к порталу.'
              }
              handleClose={handleClose}
              onSubmitEnd={onEndStep2}
            />
          )}
        </div>
      </PopupWrapperNew>
      {confirm && (
        <PopupWrapperNew
          popupBlockMod={styles.popupHelper}
          isBottom
          bgNoClicked
          closePopup={continueHandler}
        >
          <div className={styles.confirmWrapper}>
            <div className={styles.confirmTitle}>Вы уверены, что хотите прервать регистрацию? </div>
            <div className={styles.confirmSubTitle}>
              Чтобы зарегистрироваться на мероприятие, необходимо заполнить все поля и нажать кнопку
              “сохранить”.
            </div>
            <div className={styles.confirmBtns}>
              <Button modificator={styles.button} onClick={continueHandler}>
                Продолжить
              </Button>
              <Button modificator={styles.button} onClick={handleClose} theme={ButtonThemes.FOURTH}>
                Прервать
              </Button>
            </div>
          </div>
        </PopupWrapperNew>
      )}
    </>
  )
}

export default DoubleRegistartion
