import { FC } from 'react'
import { useForm, useFormState } from 'react-hook-form'

import Button from 'components/parts/button/button'
import ControllerBaseInput from 'components/form-parts/controller-hook-forms/controller-base-input/controller-base-input'
import ControllerPhoneInput from 'components/form-parts/controller-hook-forms/controller-phone-input/controller-phone-input'
import ControllerSpecialitiesInput from 'components/form-parts/controller-hook-forms/controller-specialities-input/controller-specialities-input'
import Auth from 'store/auth'
import useBannerTelegramBottom from 'store/popups/useBannerTelegramBottom'
import prepareSpecs from 'utils/profile/prepareSpecs'

import { putAxiosSingle } from 'pages/api/axios'
import { profileURL } from 'constants/url'
import { IObj } from 'constants/types/common.types'
import { InputSpecialitiesStyles } from 'components/fullscreen-popups/registration/double-registration/parts/registration-step1/data'
import {
  FieldsNameVoc,
  specialitiesValidation,
  workplceValidation,
  fullNameValidation,
} from 'constants/auth/profile-data'

import styles from './anketa-webinar.module.scss'

interface IRegistrationStep2 {
  handleClose?: () => void
}

const InputPCStyles = {
  inputNameModificator: styles.nameRowStyle,
  innerContainerModificator: styles.inputWrap,
}

const dopSpecStyle = {
  inputWrapperModificator: styles.inputWrap,
}

const RegistrationStep2: FC<IRegistrationStep2> = ({ handleClose }) => {
  const { userProfile, anketaProfile, isSubscribedToTelegram } = Auth.useAuth()
  const { setCurrentProfile } = Auth.useHandleAuth()
  const { openBannerTelegramBottom } = useBannerTelegramBottom()

  const phoneUser = userProfile.phone
  const specUser = prepareSpecs(userProfile.Specialities)
  const fullNameUser = anketaProfile.fullName
  const workUser = anketaProfile.workplace

  const { handleSubmit, control, setValue, clearErrors, getValues } = useForm({
    mode: 'onBlur',
    defaultValues: {
      [FieldsNameVoc.phone]: phoneUser || '+7',
      [FieldsNameVoc.Specialities]: specUser || [],
      [FieldsNameVoc.fullName]: fullNameUser || '',
      [FieldsNameVoc.workplace]: workUser || '',
    },
  })

  const { errors } = useFormState({ control })

  const onSubmit = async (values: IObj<any>) => {
    const [lastName, firstName, secondName] = values.fullName.trim().split(' ')
    const specId = values[FieldsNameVoc.Specialities].map(({ value }: { value: number }) => value)

    const formatedValues = {
      ...values,
      firstName: firstName,
      lastName: lastName,
      secondName: secondName,
      [FieldsNameVoc.Specialities]: specId,
    }
    const data = await putAxiosSingle(profileURL, {}, formatedValues)

    if (data.status === 200) {
      setCurrentProfile({
        formatedValues,
      })
      if (!isSubscribedToTelegram) openBannerTelegramBottom()
      handleClose?.()
    }
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.txtTop} onClick={() => console.log(getValues())}>
        Для регистрации на мероприятие, заполните недостающие поля
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        {!specUser && (
          <ControllerSpecialitiesInput
            control={control}
            rules={specialitiesValidation}
            errors={errors}
            sendGA
            nameStar
            nameInput="Специальность"
            {...InputSpecialitiesStyles}
            {...dopSpecStyle}
          />
        )}

        {!phoneUser && (
          <ControllerPhoneInput
            control={control}
            errors={errors}
            noErrorOnTouch={clearErrors}
            setValue={setValue}
            nameStar
            {...InputPCStyles}
          />
        )}
        {!fullNameUser && (
          <ControllerBaseInput
            control={control}
            name={FieldsNameVoc.fullName}
            errors={errors}
            noErrorOnTouch={clearErrors}
            rules={fullNameValidation}
            nameStar
            {...InputPCStyles}
          />
        )}
        {!workUser && (
          <ControllerBaseInput
            control={control}
            name={FieldsNameVoc.workplace}
            rules={workplceValidation}
            noErrorOnTouch={clearErrors}
            errors={errors}
            nameStar
            {...InputPCStyles}
          />
        )}
        <Button>Сохранить</Button>
      </form>
    </div>
  )
}

export default RegistrationStep2
