import { FC } from 'react'
import { Controller, Control, FieldErrors } from 'react-hook-form'

import FormSpeciality from 'components/form-parts/form-speciality-react/form-speciality-react'

import { FieldsNameVoc } from 'constants/auth/profile-data'
interface IProps {
  control: Control<any, any>
  errors?: FieldErrors<any>
  rules?: any
  name?: string
  nameInput?: string
  nameStar?: boolean
  sendGA?: boolean
}

const ControllerSpecialitiesInput: FC<IProps> = ({
  control,
  name,
  rules,
  errors,
  sendGA,
  ...props
}: IProps) => {
  return (
    <Controller
      control={control}
      name={FieldsNameVoc.Specialities}
      rules={rules}
      render={({ field: { onChange, value, ...rest } }) => {
        return (
          <FormSpeciality
            values={value}
            handleSetSpecs={onChange}
            sendGA={sendGA}
            error={errors && errors[name || FieldsNameVoc.Specialities]}
            {...rest}
            {...props}
          />
        )
      }}
    />
  )
}

export default ControllerSpecialitiesInput
