import { FC, useState } from 'react'
import { Controller, Control, FieldErrors } from 'react-hook-form'

import InputPhone from 'components/form-parts/form-phone-react/form-phone-react'

import { phoneValidation } from 'constants/auth/profile-data'

interface IProps {
  control: Control<any, any>
  errors: FieldErrors<any>
  dynamicName?: boolean
  noErrorOnTouch?: any
  noName?: boolean
  nameStar?: boolean
  setValue: any
}

const ControllerPhoneInput: FC<IProps> = ({
  control,
  errors,
  setValue,
  dynamicName,
  noName,
  noErrorOnTouch,
  ...props
}: IProps) => {
  const [country, setCountry] = useState('')
  return (
    <Controller
      control={control}
      name="phone"
      rules={phoneValidation(country)}
      render={({ field: { ref, value, ...rest } }) => {
        return (
          <InputPhone
            error={errors.phone}
            inputRef={ref}
            setValue={setValue}
            value={value}
            dynamicName={dynamicName}
            onClick={() => noErrorOnTouch?.('phone')}
            setCountry={setCountry}
            noName={noName}
            {...rest}
            {...props}
          />
        )
      }}
    />
  )
}

export default ControllerPhoneInput
