import { FC, useMemo } from 'react'

import FormSpecsMulti from '../form-select-multi-react/form-select-multi-react'
import useSpecialities from 'store/lists/useSpecialities'

import numericToWord from 'utils/numericToWord'
import { fieldData } from 'constants/auth/profile-data'
import { IOptions } from 'constants/types/common.types'

import commonStyles from '../../profile/edit-profile/edit-profile.module.scss'

interface IFormSpeciality extends React.InputHTMLAttributes<HTMLInputElement> {
  handleSetSpecs: (values: any) => void
  handleBlurSelect?: () => void
  customSelectStyle?: any
  onFocus?: () => void
  error?: any
  nameInput?: string
  nameStar?: boolean
  sendGA?: boolean
  inputWrapperModificator?: string
  values: IOptions[]
}

const SPECIALITY_WORD_FORM = ['специальность', 'специальности', 'специальностей']
const DEFAULT_SPECALITY = 'Выберите из списка'

const FormSpeciality: FC<IFormSpeciality> = ({
  handleSetSpecs,
  handleBlurSelect,
  customSelectStyle,
  onFocus,
  error,
  nameInput,
  nameStar,
  inputWrapperModificator,
  sendGA,
  ...props
}) => {
  const specialities = useSpecialities()

  const specialitiesLength = props.values.length

  const specialitiesTitle = useMemo(() => {
    if (!specialitiesLength) return DEFAULT_SPECALITY
    return `${specialitiesLength} ${numericToWord(specialitiesLength, SPECIALITY_WORD_FORM)}`
  }, [specialitiesLength])

  const nameInputFormated = nameStar ? `${nameInput}*` : nameInput

  return (
    <FormSpecsMulti
      {...fieldData.Specialities}
      nameInput={nameInputFormated || ''}
      title={specialitiesTitle}
      options={specialities}
      values={props.values}
      error={error || ''}
      inputNameModificator={commonStyles.nameRowStyle}
      innerBoxModificator={commonStyles.InnerContainerStyle}
      inputWrapperModificator={inputWrapperModificator}
      btnModificator={
        specialitiesLength ? commonStyles.selectBtnStyleActive : commonStyles.selectBtnStyle
      }
      setValues={handleSetSpecs}
      blurSelect={handleBlurSelect}
      onFocus={onFocus}
      customStyles={customSelectStyle}
      sendGA={sendGA}
    />
  )
}

export default FormSpeciality
