import { FC } from 'react'

import styles from './auth-popup-wrapper.module.scss'

interface IAuthPopupWrapper {
  title?: string
  children: React.ReactNode
}

const AuthPopupWrapper: FC<IAuthPopupWrapper> = ({ title, children }) => {
  return (
    <div className={styles.popupWrapper}>
      <div className={styles.header}>
        <h3 className={styles.headerTitle}>{title}</h3>
      </div>
      <div className={styles.outerContainer}>{children}</div>
    </div>
  )
}

export default AuthPopupWrapper
