import { FC, useState } from 'react'
import { useRouter } from 'next/router'
import { useForm, useFormState } from 'react-hook-form'
import Image from 'next/image'

import ControllerBaseInput from 'components/form-parts/controller-hook-forms/controller-base-input/controller-base-input'
import Button from '../../parts/button/button'
import AuthPopupWrapper from '../auth-popup-wrapper/auth-popup-wrapper'

import A from 'utils/metrics/analytic'
import { A_AUTH_LOGIN_ERRORS } from 'constants/analytic-data'
import { SessionStorageKeys } from 'constants/storage'
import { emailValidation, FieldsNameVoc } from 'constants/auth/profile-data'
import { postAxiosSingle } from 'pages/api/axios'
import { cutHashFromURL } from 'utils/getURLData'
import { popupName } from 'constants/auth/auth-data'
import { EMAIL_URL, STATIC_URL } from 'constants/data'

import styles from './recover-password.module.scss'

const imgPath = {
  preload: `${STATIC_URL}/preload.svg`,
}

const RecoverPassword: FC = () => {
  const router = useRouter()
  const path = cutHashFromURL(router.asPath)

  const successRecovery = () =>
    router.push(`${path}#${popupName.recoverSuccess}`, undefined, { shallow: true })

  const [inUse, setInUse] = useState(false)

  const { handleSubmit, control, clearErrors, setError } = useForm({
    mode: 'onBlur',
  })

  const onSubmit = async (values: any) => {
    setInUse(true)
    try {
      const data =
        (await postAxiosSingle(EMAIL_URL + '/resetpassword', {}, { email: values.email })) || {}

      if (data.status === 200) {
        sessionStorage.setItem(SessionStorageKeys.PASSWORD_RECOVER, values.email)
        return successRecovery()
      }
      throw Error()
    } catch {
      setError(FieldsNameVoc.email, { type: 'custom', message: 'Пользователь не найден' })
      A.setEvent(A_AUTH_LOGIN_ERRORS, { type: 'noacc' })
      setInUse(false)
    }
  }

  const { errors } = useFormState({ control })

  const handleKeyPress = async (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      handleSubmit(onSubmit)
    }
  }

  const formHasErrors = Boolean(Object.keys(errors).length)
  const isButtonDisabled = inUse || formHasErrors

  return (
    <div className={styles.wrapper}>
      <AuthPopupWrapper title="Восстановление пароля">
        <form id="form" onSubmit={handleSubmit(onSubmit)} onKeyDown={handleKeyPress}>
          <p className={styles.mainContent}>
            Для восстановления пароля укажите электронную почту, которую вы использовали для
            регистрации
          </p>
          <div className={styles.inputContainer}>
            <ControllerBaseInput
              control={control}
              name={FieldsNameVoc.email}
              errors={errors}
              noErrorOnTouch={clearErrors}
              rules={emailValidation}
            />
          </div>
          <div className={styles.sendBlock}>
            <div className={styles.line}>
              <div className={styles.button}>
                {!inUse && (
                  <Button type="submit" disabled={isButtonDisabled}>
                    Отправить
                  </Button>
                )}
                {inUse && <Image src={imgPath.preload} width="50" height="50" alt="load icon" />}
              </div>
            </div>
          </div>
        </form>
      </AuthPopupWrapper>
    </div>
  )
}

export default RecoverPassword
