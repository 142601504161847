import { FC, useRef, useState, useMemo, useEffect, ReactNode } from 'react'
import Select, { StylesConfig } from 'react-select'
import clsx from 'clsx'
import Image from 'next/image'

import useOnClickOutside from 'hooks/useClickOutside'
import SelectArrow from '../select-arrow/select-arrow'

import A from 'utils/metrics/analytic'
import { getParts } from './utils'
import { IOptions } from 'constants/types/common.types'
import { SelectPropsData } from './data'
import { A_REGISTRATION_CLICK_FORM_FIELD } from 'constants/analytic-data'
import { STATIC_URL } from 'constants/data'

import { selectStyles } from './styles'
import styles from './select-multi.module.scss'

let isTouchSpeciality = false
const searchIconPath = `${STATIC_URL}/filters/search-icon.svg`

interface IMultipleSelect {
  options: IOptions[]
  title?: string | ReactNode
  placeholder: string
  values: IOptions[]
  setValues: (selected: IOptions[]) => void
  modificator?: string
  btnModificator?: string
  error?: boolean
  tabIndex?: number
  blurSelect?: () => void
  onClick?: () => void
  onClose?: () => void
  customStyles?: Partial<StylesConfig<IOptions, boolean>>
  sendGA?: boolean
}

const SelectMulti: FC<IMultipleSelect> = ({
  options,
  title,
  placeholder,
  values,
  setValues,
  modificator,
  btnModificator,
  error,
  tabIndex,
  sendGA,
  blurSelect,
  customStyles,
  onClick,
  onClose,
}: IMultipleSelect) => {
  const [isOpen, setOpen] = useState(false)
  const [sortSpec, setSortSpec] = useState<any>()
  const selectRef = useRef<any>(null)
  const wrapperRef = useRef<HTMLDivElement>(null)

  const handleChange = (selected: any) => {
    setValues(selected || '')
    if (sendGA && !isTouchSpeciality) {
      A.setEvent(A_REGISTRATION_CLICK_FORM_FIELD, {
        title_field: 'Specialities_list',
        subSource: '',
      })
    }
    isTouchSpeciality = true
  }

  const handleBlur = () => {
    setOpen(false)
    selectRef?.current?.blur()
    blurSelect && blurSelect()
  }

  useOnClickOutside(isOpen, wrapperRef, handleBlur)

  const handleSubmit = (e: React.SyntheticEvent<HTMLElement>) => {
    if ((e.target as HTMLElement).dataset?.blur) {
      selectRef?.current?.blur()
    }
  }

  const parts = useMemo(
    () => getParts(modificator || '', values?.length || 0),
    [modificator, values?.length]
  )

  const handleClick = () => {
    setOpen((prevState) => {
      prevState && onClose && onClose()
      return !prevState
    })
    onClick && onClick()
  }
  useEffect(() => {
    const yourSpecId = values.map((element) => element.value) || []
    const otherSpec = options.filter((element) => {
      if (yourSpecId.includes(element.value)) return null
      return element
    })
    const result = [...values, ...otherSpec]
    setSortSpec(result)
    if (sendGA && isOpen) {
      A.setEvent(A_REGISTRATION_CLICK_FORM_FIELD, { title_field: 'Specialities', subSource: '' })
    }
  }, [isOpen])

  return (
    <div className={styles.select} ref={wrapperRef} onClick={handleSubmit}>
      <button
        className={clsx(styles.selectBtn, {
          [btnModificator as string]: btnModificator,
          [styles.selectActive]: isOpen,
          [styles.error]: error,
        })}
        type="button"
        onClick={handleClick}
      >
        <div className={styles.selectBtnText}>{title}</div>
        <SelectArrow isActive={isOpen} />
      </button>
      {isOpen && (
        <div className={styles.inputWrapper}>
          <Image
            className={styles.iconloop}
            width={16}
            height={16}
            alt=""
            src={searchIconPath}
          ></Image>
          <Select
            {...SelectPropsData}
            ref={selectRef}
            tabIndex={tabIndex || 0}
            instanceId={placeholder + 'select'}
            placeholder={placeholder}
            styles={{ ...selectStyles, ...customStyles }}
            components={parts}
            options={sortSpec}
            value={values}
            onChange={handleChange}
          />
        </div>
      )}
    </div>
  )
}

export default SelectMulti
