const SelectPropsData = {
  menuIsOpen: true,
  isMulti: true,
  isClearable: false,
  captureMenuScroll: false,
  closeMenuOnSelect: false,
  hideSelectedOptions: false,
  controlShouldRenderValue: false,
  menuShouldScrollIntoView: true,
  backspaceRemoves: true,
  deleteRemoves: true,
}

export { SelectPropsData }
