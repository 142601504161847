enum scenarioName {
  FUTURE_WEBINAR_SCENARIO = 'FUTURE_WEBINAR_SCENARIO',
  MAIN_PAGE_SCENARIO = 'MAIN_PAGE_SCENARIO',
  ACTIVE_WEBINAR_SCENARIO = 'ACTIVE_WEBINAR_SCENARIO',
}

enum stepName {
  FILL_PROFILE = 'FILL_PROFILE',
  GO_TO_NEWSLINE = 'GO_TO_NEWSLINE',
  LK = 'LK',
  CONGRATS = 'CONGRATS',
  CHAT = 'CHAT',
  LK_ACTIVE_WEBINAR = 'LK_ACTIVE_WEBINAR',
}

const scenario = {
  [scenarioName.FUTURE_WEBINAR_SCENARIO]: [stepName.FILL_PROFILE, stepName.GO_TO_NEWSLINE],
  [scenarioName.MAIN_PAGE_SCENARIO]: [stepName.CONGRATS, stepName.LK],
  [scenarioName.ACTIVE_WEBINAR_SCENARIO]: [stepName.CHAT, stepName.LK_ACTIVE_WEBINAR],
}

export { scenarioName, stepName, scenario }
